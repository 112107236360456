<!--
* 创建人：邓国伦
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
 <div class="container">
    系统配置
 </div>
</template>
<!-- javascript脚本-->
<script>
 export default {
   data () {
     return {

     }
   },
   created() {

   },
   methods: {

   },
   mounted() {

   },
 }
</script>
<!-- CSS样式 -->
<style>

</style>
